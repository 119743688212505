
    .creation-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .creation-progress {
            display: flex;

            .progress-content {
                margin: 64px auto;
                display: flex;
                align-items: center;

                .step-num {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span:nth-child(1) {
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        color: #FFFFFF;
                        background: #DCDCDC;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 48px;
                    }

                    .active {
                        background: #1122D8 !important;
                    }

                    span:nth-child(2) {
                        display: inline-block;
                        width: 100px;
                        text-align: center;
                        top: 68px;
                        /*left: 30%;*/
                        position: absolute;
                        font-size: 16px;
                        color: #333333;

                    }
                }

                .step-wire {
                    height: 14px;
                    margin: 0 10px;
                }
            }
        }

        .creation-form {
            flex: 1;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 40px 0 30px 30px;
                    height: calc(100% - 70px);
                }
            }

            .step-one {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .time-tips {
                    margin-left: 10px;
                    color: #999999;
                }

                ::v-deep .el-form-item__label {
                    color: #13131B;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }

                .form-bottom {
                    text-align: center;

                    .blue-button {
                        background: #1122D8;
                        color: #fff;
                    }
                }
            }

            .step-two {
                .succeed-icon {
                    padding-top: 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 52px;
                        color: #20D28C;
                    }

                    span {
                        color: #343434;
                        font-size: 24px;
                        font-weight: bold;
                    }
                }

                .getBack {
                    display: flex;
                    justify-content: center;
                    padding-top: 400px;

                    .getBackBtn {
                        width: 100px;
                        height: 30px;
                        background: #ffffff;
                        border-color: #1222D8;
                        color: #1222D8;
                        line-height: 0;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
